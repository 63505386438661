<template>
  <div>
    <div class="wz">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    
    <div class="box">
      <div class="serarch">
        <div class="searchleft">
          <el-form :inline="true" :model="searchform" class="demo-form-inline">
            <el-form-item class="input-item">
              <el-input
                placeholder="请输入用户名/昵称"
                v-model.trim="inputSeache"
                @keyup.enter.native="
                  pageNum = 1;
                  getAdminMember();
                "
                
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search lh"
                  @click="
                    pageNum = 1;
                    getAdminMember();
                  "
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item label="角色">
              <el-select
                v-model="userRoleId"
                @change="
                  pageNum = 1;
                  getAdminMember();
                "
                placeholder="全部"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="item in roleList"
                  :key="item.roleId"
                  :label="item.roleName"
                  :value="item.roleId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="userStatus"
                @change="
                  pageNum = 1;
                  getAdminMember();
                "
                placeholder="全部"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="未冻结" value="0"></el-option>
                <el-option label="已冻结" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="searchright">
          <el-button
            type="primary"
            style="margin: 10px 40px"
            @click="addUserBtn"
            icon="el-icon-circle-plus-outline"
            class="mybotton"
            >添加管理员</el-button
          >
        </div>
      </div>
      <div class="list" v-loading="loading">
        <el-table
          :data="tableData"
          :header-cell-style="{
            'font-weight': 'bold',
            color: '#555555',
            padding: '6px 0',
          }"
          style="width: 100%"
          v-loading="loading"
          row-key="id"
          border
          stripe
        >
          <el-table-column prop="id" align="center" label="ID" width="100">
          </el-table-column>
          <el-table-column
            prop="account"
            align="center"
            label="用户名"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="headImg"
            align="center"
            label="头像"
            width="100"
          >
            <template slot-scope="scope">
              <show-img
                v-if="scope.row.headImg"
                :url="scope.row.headImg"
                width="300"
                wid="40px"
              ></show-img>
              <show-img v-else :url="LogoUrl" width="300" wid="40px"></show-img>
            </template>
          </el-table-column>
          <el-table-column
            prop="userName"
            align="center"
            label="昵称"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="roleName"
            align="center"
            label="角色"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            label="创建时间"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="updateTime" align="center" label="更新时间">
          </el-table-column>
          <el-table-column prop="" align="center" label="是否冻结">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="true"
                :inactive-value="false"
                active-color="#404040"
                inactive-color="#b8b8b8"
                active-text=""
                inactive-text=""
                @change="changestatu($event, scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="" width="200" align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="handleCreate(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                @click="deleteUser(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <app-page
        :total="total"
        :pageSize="pageSize"
        :current="pageNum"
        @handleCurrentChange="currentchange"
        @handleSizeChange="handchange"
      ></app-page>
    </div>
    <!-- 添加 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="canvel"
    >
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formAddAdmin"
        :rules="rules"
        ref="formAddAdmin"
      >
      
        <el-form-item label="头像">
          <div class="block">
            <upload-img
              :modeldata="formAddAdmin"
              @changeurl="getimgurl"
              style="margin-top: 15px"
            ></upload-img>
          </div>
        </el-form-item>
        <el-form-item label="用户名" prop="account">
          <el-input v-model="formAddAdmin.account"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="formAddAdmin.password"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="userName">
          <el-input v-model="formAddAdmin.userName"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="newUserRoleId">
          <el-select
            v-model="formAddAdmin.newUserRoleId"
            size="small"
            placeholder="请选择"
            @change="changeRole"
          >
            <el-option
              v-for="item in roleList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="管理分站" prop="roleDescribe" v-show="isshoworg && userInfo.orgId==0">
          <el-select v-model="formAddAdmin.orgId" placeholder="请选择管理的分站">
            <el-option
              v-for="item in orglist"
              :key="item.orgId"
              :label="item.districtName"
              :value="item.orgId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <el-button @click="canvel">取 消</el-button>
          <el-button
            type="primary"
            v-preventReClick
            @click="addUser('formAddAdmin')"
            :loading="btnLoad"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible1"
      :close-on-click-modal="false"
      @close="canvel1"
    >

      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formAddAdmin1"
        :rules="rules"
        ref="formAddAdmin1"
      >
        <el-form-item label="用户名">
          <el-tag type="info">{{ formAddAdmin1.account }}</el-tag>
        </el-form-item>
        <el-form-item label="头像" prop="">
          <div class="block">
            <upload-img
              :modeldata="formAddAdmin1"
              @changeurl="getimgurl"
              style="margin-top: 15px"
            ></upload-img>
          </div>
        </el-form-item>
        <el-form-item label="昵称" prop="userName">
          <el-input v-model="formAddAdmin1.userName"></el-input>
        </el-form-item>
        <el-form-item v-if="isShowPwd" label="密码" prop="newPassword">
          <div>
            <el-input
              v-model="formAddAdmin1.newPassword"
              style="width: 600px"
            ></el-input>
            <el-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="
                isShowPwd = false;
                formAddAdmin1.newPassword = '';
              "
              class="mybotton"
              >返回</el-button
            >
          </div>
        </el-form-item>
        <el-form-item v-else label="密码" prop="">
          <el-button
            type="primary"
            style=""
            size="small"
            @click="isShowPwd = true"
            class="mybotton"
            >去修改</el-button
          >
        </el-form-item>
        <el-form-item label="角色" prop="newUserRoleId">
          <el-select
            v-model="formAddAdmin1.newUserRoleId"
            size="small"
            placeholder="请选择"
            @change="changeRole2"
          >
            <el-option
              v-for="item in roleList"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="管理分站" prop="roleDescribe" v-show="isshoworg && userInfo.orgId==0">
          <el-select v-model="formAddAdmin1.orgId" placeholder="请选择管理的分站">
            <el-option
              v-for="item in orglist"
              :key="item.orgId"
              :label="item.districtName"
              :value="item.orgId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <el-button @click="canvel1">取 消</el-button>
          <el-button
            type="primary"
            v-preventReClick
            @click="updateUser('formAddAdmin1')"
            :loading="btnLoad"
            >修改</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
let checkpassword = (rule, value, cb) => {
  // const regPassword = /^[a-z0-9]{6,16}$/
  const regPassword = /^[0-9a-zA-Z]{6,12}$/g;
  if (regPassword.test(value)) {
    return cb();
  }
  cb(new Error("密码只能由6-12位字母或数字组成"));
};
import _api from "@/api/index";
import apiUrl from "@/api/public";
export default {
  data() {
    return {
      isshoworg:false,
      orglist:JSON.parse(localStorage.getItem("org")),
      LogoUrl:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      searchform: {
        jobName: "",
        status: "",
      },
      tableData: [], //权限列表数据
      defaultArr: [], //默认展开行
      dialogVisible: false,
      dialogVisible1: false,
      formAddAdmin: {
        userName: "",
        account: "",
        password: "",
        newUserRoleId: null,
        url: "",
        orgId:null,
      },
      formAddAdmin1: {
        userName: "",
        newPassword: "",
        newUserRoleId: null,
        url: "",
        orgId:null
      },
      rules: {
        userName: [
          { required: true, message: "用户昵称不能为空", trigger: "blur" },
        ],
        account: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { validator: checkpassword, trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { validator: checkpassword, trigger: "blur" },
        ],
        newUserRoleId: [
          { required: true, message: "权限不能为空", trigger: "blur" },
        ],
      },
      list: [],
      data: [],
      loading: false,

      labelPosition: "right", //控制表单对齐方式
      btnLoad: false,
      id: null,
      userInfo: {},
      total: 0,
      pageNum: 1,
      pageSize: 10,
      userName: "",
      account: "",
      roleId: 1,
      inputSeache: "",
      roleList: [],
      userRoleId: "",
      userStatus: null,
      isShowPwd: false,
    };
  },
  created() {
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    this.getAdminMember();
    this.getRoleList();
  },
  methods: {
    changeRole(){//选择角色后
      let find=this.roleList.find((n)=>(n.roleId==this.formAddAdmin.newUserRoleId))
      console.log(find)
      this.formAddAdmin.orgId=null
      if(find.isOrg==1){
        this.isshoworg=true
      }else{
        this.isshoworg=false
      }
    },
    changeRole2(){//选择角色后
      let find=this.roleList.find((n)=>(n.roleId==this.formAddAdmin1.newUserRoleId))
      console.log(find)
      this.formAddAdmin1.orgId=null
      if(find.isOrg==1){
        this.isshoworg=true
      }else{
        this.isshoworg=false
      }
    },
    changestatu(status, row) {
      //切换发布状态
      _api
        .post(apiUrl.lockMember, {
          memberId: row.id,
          status: status,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRoleList() {
      _api
        .get(apiUrl.queryAdminRole, {})
        .then((res) => {
          if (res.success) {
            let roleList = [];
            let data = res.data;
            data.forEach((it) => {
              roleList.push({
                roleId: it.id,
                roleName: it.roleName,
                isOrg: it.isOrg,
              });
            });
            this.roleList = roleList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getupData(url) {
      this.ruleForm.imgUrl = url;
    },
    getimgurl(data) {
      this.formAddAdmin.url = data;
    },
    // 取消
    canvel() {
      this.dialogVisible = false;
      this.formAddAdmin.account = this.formAddAdmin.userName = this.formAddAdmin.password = this.formAddAdmin.newUserRoleId = this.formAddAdmin.url =
        "";
    },
    canvel1() {
      this.dialogVisible1 = false;
      this.formAddAdmin1.newPassword = ""
      this.isShowPwd = false
    },
    addUserBtn() {
      this.dialogVisible = true;
    },
    //添加用户
    addUser(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.formAddAdmin;
          const data = {
            account: form.account,
            userName: form.userName,
            password: form.password,
            roleId: form.newUserRoleId,
            orgId: form.orgId,
            headImg: form.url,
          };
          _api
            .get(apiUrl.checkAccount, {
              account: data.account,
            })
            .then((res) => {
              if (res.success) {
                this.addUser1(data);
              }
            })
            .catch((err) => {
              this.$message({
                message: "该用户名已存在",
                type: "warning",
              });
            });
        } else {
          this.$message.error("信息未填写完整，请检查！");
          return false;
        }
      });
    },
    addUser1(data) {
      _api
        .post(apiUrl.addMember, data)
        .then((res) => {
          if (res.success) {
            this.canvel();
            this.getAdminMember();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateUser(formName) {
      const regPassword = /^[0-9a-zA-Z]{6,12}$/g;
      let result = regPassword.test(this.formAddAdmin1.newPassword);
      if (result || !this.formAddAdmin1.newPassword) {
        this.btnLoad = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const data = {
              id: this.id,
              userName: this.formAddAdmin1.userName,
              password: this.formAddAdmin1.newPassword,
              roleId: this.formAddAdmin1.newUserRoleId,
              headImg: this.formAddAdmin1.url,
              orgId: this.formAddAdmin1.orgId,
            };
            _api
              .post(apiUrl.updateMember, data)
              .then((res) => {
                if (res.success) {
                  this.$message.success("修改成功");
                  this.btnLoad = false;
                  this.canvel1();
                  this.getAdminMember();
                }
              })
              .catch((err) => {
                this.btnLoad = false;
                console.log(err);
              });
          } else {
            this.btnLoad = false;
            this.$message.error("信息未填写完整，请检查！");
            return false;
          }
        });
      }else{
        this.$message.error("密码只能由6-12位字母或数字组成");
      }
    },
    getAdminMember() {
      this.loading = true;
      if (this.inputSeache != "" && this.inputSeache != null) {
        var data = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userName: this.inputSeache,
          roleId: this.userRoleId,
          status: this.userStatus,
        };
      } else {
        var data = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userName: this.inputSeache,
          roleId: this.userRoleId,
          status: this.userStatus,
        };
      }
      _api
        .post(apiUrl.queryAdminMember, data)
        .then((res) => {
          this.loading = false;
          if (res.success) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCreate(id) {
      this.id = id;
      this.dialogVisible1 = true;
      this.selectMemberById(id);
    },
    selectMemberById(id) {
      _api
        .get(apiUrl.selectMemberById, {
          memberId: id,
        })
        .then((res) => {
          if (res.success) {
            this.formAddAdmin1.userName = res.data.userName;
            this.formAddAdmin1.account = res.data.account;
            this.formAddAdmin1.password = res.data.password;
            this.formAddAdmin1.newUserRoleId = res.data.roleId;
            this.formAddAdmin1.url = res.data.headImg;
            this.formAddAdmin1.orgId = res.data.orgId;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteUser(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _api
            .get(apiUrl.deleteMember, {
              memberId: id,
            })
            .then((res) => {
              if (res.success) {
                this.$message.success("删除成功");
                this.getAdminMember();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handchange(data) {
      //分页Size变化
      this.pageSize = data;
      this.pageNum = 1;
      this.getAdminMember();
    },
    currentchange(data) {
      //当前页变化
      this.pageNum = data;
      this.getAdminMember();
    },
  },
};
</script>
<style>
.el-loading-spinner .path {
  stroke: #fe8196;
}
.el-switch__label {
  color: #bdbdbd;
}
.el-switch__label.el-switch__label--right.is-active {
  color: #2ad1b8;
}
.el-switch__label.el-switch__label--left.is-active {
  color: #fe8496;
}
</style>
<style scoped>
@import "../../assets/css/booth.css";
.serarch {
  overflow: hidden;
}
</style>
